import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';


import {Notifications} from '@mantine/notifications';
import {PublicHomePage} from './features/home/pages/PublicHomePage';
import {SignOutPage} from './features/home/pages/SignOutPage';

import {IndexPage as DashboardPage} from "./features/dashboard/pages/IndexPage";
import {AppLayout} from "./layout/AppLayout";
import {RequireAuth} from "./utils/RequireAuth.tsx";
import {MessagesLayout} from "./features/messages/layout.tsx";
import {AliasesLayout} from "./features/aliases/layout.tsx";
import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";

import {ModalsProvider} from "@mantine/modals";
import {ContactsLayout} from "./features/contacts/layout.tsx";

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";
import {PricingPage} from "./features/home/pages/PricingPage.tsx";
import {AboutPage} from "./features/home/pages/AboutPage.tsx";
import {ProductsPage} from "./features/home/pages/ProductsPage.tsx";
import {ServicePlansLayout} from "./features/service-plans/layout.tsx";
import {AuthProvider} from "./context/AuthContext.tsx";


const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'about',
        element: <AboutPage/>
    }, {
        path: 'products',
        element: <ProductsPage/>
    }, {
        path: 'pricing',
        element: <PricingPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'messages/*',
        element: <RequireAuth><MessagesLayout/></RequireAuth>
    }, {
        path: 'aliases/*',
        element: <RequireAuth><AliasesLayout/></RequireAuth>
    }, {
        path: 'contacts/*',
        element: <RequireAuth><ContactsLayout/></RequireAuth>
    }, {
        path: 'service-plans/*',
        element: <RequireAuth><ServicePlansLayout/></RequireAuth>
    }]
}])

export const App = () => {

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <UserAttributesContextProvider>
                    <ModalsProvider>
                        <Notifications position={'bottom-left'}/>
                        <RouterProvider router={router}/>
                    </ModalsProvider>
                </UserAttributesContextProvider>
            </AuthProvider>
        </MantineProvider>
    );
}

