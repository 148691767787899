import {
    deleteMessage,
    fetchMessage,
    saveMessage,
    updateMessage,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Message} from '../types';

export const useMessage = (messageId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, messageId ? messageId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof messageId === 'undefined') {
            throw new Error('messageId is required parameter');
        }
        dispatch(fetchMessage(messageId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Message) => {
        dispatch(saveMessage(values));
    };

    const updateRecord = (values: Message) => {
        if (typeof messageId === 'undefined') {
            throw new Error('messageId is required parameter');
        }

        dispatch(updateMessage({
                                   id: messageId,
                                   record: values,
                               }));
    };

    const deleteRecord = () => {
        if (typeof messageId === 'undefined') {
            throw new Error('messageId is required parameter');
        }
        dispatch(deleteMessage(messageId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
