export const listAliases = `
   query listAliases {
       listAliases {
           items {
              id
              name
              description
              sub
              status
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getAlias = `
   query getAlias($id:ID!) {
       getAlias(id:$id) {
         id
         name
         description
         sub
         status
         createdByUsername
         created
         updated
       } 
}`;
