import {NavLink as ReactRouteNavLink} from 'react-router-dom';
import {TbGauge} from "react-icons/tb";
import {DashboardWidgetBuilder} from "./DashboardWidgetBuilder.tsx";
import {
    WidgetDataPoint,
    WidgetType
} from "../../types.ts";
import {useUserAttributes} from "../../../../hooks/useUserAttributes.ts";

export const ProfileWidget = () => {
    const {
        givenName,
        familyName,
        email,
        companyName
    } = useUserAttributes()


    const widget: WidgetType = {
        title: `Good Morning, ${givenName} ${familyName}`,
        dataPoints: [
            {
                value: null,
                label: `Organization: ${companyName}`,
                color: 'black',
            },
            {
                value: null,
                label: `Email: ${email}`,
                color: 'black',
            },
        ] as WidgetDataPoint[],
        metrics: [],
        actionItems: [
            {
                label: 'Update Profile',
                description: 'Update your platform profile',
                icon: <TbGauge size={16} stroke={'1.5'}/>,
                component: {ReactRouteNavLink},
                to: '/profile',
                visible: true,
            },
        ],
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
