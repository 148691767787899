import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/ServicePlansService';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {ServicePlan} from '../types';
import {moduleConfig} from "../config";

const entityKey = moduleConfig.entityKey;

interface ServicePlansState {
    byId: { [key: string]: ServicePlan },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as ServicePlansState;


const normalizeRecord = (record: ServicePlan) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchServicePlans = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: ServicePlan) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchServicePlan = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveServicePlan = createAsyncThunk(
    `${entityKey}/add`,
    (payload: ServicePlan) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateServicePlan = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: ServicePlan }) => {

        const updated = Object.assign({}, {
            id: record.id,
            name: record.name,
        } as ServicePlan)

        return update(id, updated)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteServicePlan = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeServicePlan = createAsyncThunk(
    `${entityKey}/store`,
    (payload: ServicePlan) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchServicePlans, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchServicePlan);

                                            // SAVE ONE
                                            saveOneCase(builder, saveServicePlan);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateServicePlan);

                                            updateOneCase(builder, storeServicePlan);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteServicePlan);
                                        },
                                    });

export default componentsSlice.reducer;
