import {Message,} from '../types';
import {MessageHtml} from "./MessageHtml.tsx";
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {
    Group,
    Paper,
    Stack,
    Text
} from "@mantine/core";
import {
    FlexiButton,
    FlexiDeleteButton,
    PlatformPropertyCard
} from "@flexinet/ui-components";
import {useMessage} from "../hooks/useMessage.ts";
import {
    AiOutlinePaperClip,
    AiOutlineRead
} from "react-icons/ai";

import {getUrl} from 'aws-amplify/storage';

export const SideMessagePanel = ({record}: { record: Message }) => {

    const {
        updateRecord,
        deleteRecord
    } = useMessage(record.id as string)

    const properties = [
        {
            label: 'From',
            value: record.fromText,
            span: 6,
        }, {
            label: 'To',
            value: record.toText,
            span: 6,
        }, {
            label: 'Received',
            value: formatTimestamp(record.date as string),
            span: 6,
        }, {
            label: 'Status',
            value: record.status,
            span: 6,
        }];


    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        selected,
        ...rest
    } = record as Message & { selected: boolean };


    const showAttachment = async (attachment: { filename: string, filePath: string, objectKey: string }) => {

        const getUrlResult = await getUrl({
                                              path: attachment.filePath
                                          });

        console.debug(getUrlResult.url)
        window.open(getUrlResult.url, '_blank')

    };


    return (
        <Paper shadow={'sm'}>
            <Stack>

                <Group p={'sm'}>
                    <FlexiButton leftSection={<AiOutlineRead/>} variant="subtle" onClick={() => {

                        const newStatus = record.status === 'READ' ? 'UNREAD' : 'READ';
                        updateRecord({...rest, ...{status: newStatus}});

                    }}>Read / Unread </FlexiButton>


                    <FlexiDeleteButton variant="subtle" onClick={() => {
                        deleteRecord();
                    }}/>
                </Group>

                <PlatformPropertyCard properties={properties}/>

                {record.attachments && record.attachments.length > 0 &&
                    <Group p={'sm'}>
                        {record.attachments.map(attachment => <Paper withBorder
                                                                     p={'md'}
                                                                     shadow={'sm'}
                                                                     onClick={() => showAttachment(attachment)}
                                                                     style={{cursor: 'pointer'}}
                        ><Group><AiOutlinePaperClip
                            size={'20'}/><Text>{attachment.filename}</Text></Group></Paper>)}
                    </Group>
                }

                <MessageHtml record={record}/>
            </Stack>
        </Paper>
    )
};

