import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          name: Yup.string()
                                                   .required('Required'),
                                          status: Yup.string()
                                                     .oneOf(['Operational', 'Partial Outage', 'Major Outage'])
                                                     .required('Required'),
                                      });
