import {config as API} from './api';
import {config as Auth} from './auth';
import {appSyncConfig} from "./appsync.ts";
import {config as Storage} from './storage';
import {ResourcesConfig} from "aws-amplify";


const endpoint = appSyncConfig.endpoint;

export const config = {
    Auth: Auth,
    API: {
        REST: API,
        GraphQL: {
            endpoint: endpoint,
            region: 'us-west-2',
            defaultAuthMode: 'userPool'
        }
    },
    Storage: Storage
} as ResourcesConfig;