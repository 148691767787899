export default {
    roleName: 'User',
    permissionStatements: [
        {
            Effect: 'Allow',
            Action: [
                'ViewAccount',
                'UpdateAccount',
            ],
            Resource: '*',
        },
        {
            Effect: 'Allow',
            Action: [
                'ViewDashboard',
                'ListUserSettings',
                'ListUserAgreements',
                'SignOut',
            ],
            Resource: '*',
        },
    ],
};
