import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';


import {
    AiOutlineAim,
    AiOutlineHome,
    AiOutlineMail,
    AiOutlineTransaction,
    AiOutlineUser
} from 'react-icons/ai'

import {IconContext} from "react-icons";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'ViewDashboard',
        }, {
            id: uuidv4(),
            link: '/messages',
            label: 'Email Messages',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineMail/></IconContext.Provider>,
            rbac: 'ListEmailMessages',
            children: [],
        }, {
            id: uuidv4(),
            link: '/aliases',
            label: 'Aliases',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAim/></IconContext.Provider>,
            rbac: 'ListAliases',
            children: [],
        }, {
            id: uuidv4(),
            link: '/contacts',
            label: 'Contacts',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineUser/></IconContext.Provider>,
            rbac: 'ListContacts',
            children: [],
        }, {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Service Plans',
            icon: <AiOutlineTransaction size={'2em'}/>,
            rbac: 'ListServicePlans',
        }] as PlatformNavbarMenuItem[];

};

