import {v4 as uuidv4} from 'uuid';
import {Contact} from "../types.ts";

const messages = [
    {
        id: uuidv4(),
        name: 'John Doe',
        emailAddress: 'john.doe@example.com',
    },
] as Contact[];

export const fetchRecords = (): Promise<{ data: { items: Contact[] } }> => {

    return new Promise(resolve => resolve({
                                              data: {items: messages}
                                          }))
}

export const fetch = (id: string): Promise<{ data: Contact }> => {

    const found = messages.find(message => message.id === id)

    return new Promise(resolve => {
        if (found) {
            resolve({data: found})
        }
    })
}
export const add = (payload: Contact): Promise<Contact> => {
    return new Promise(resolve => resolve(payload))
}

export const update = (id: string, record: Contact): Promise<Contact> => {
    console.debug(id)
    return new Promise(resolve => resolve(record))
}

export const deleteRecord = (id: string) => {
    return new Promise(resolve => resolve(id))
}