import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from './pages/EditPage';

import {useAccessControl} from '../../hooks/useAccessControl';
import {PlatformLoader} from "@flexinet/ui-components";
import {useAuth} from "../../hooks/useAuth.ts";

export const ServicePlansLayout = () => {

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    if (role === '') {
        return <PlatformLoader message={'Loading role...Please wait!'}/>
    }


    return (
        <Routes>
            <Route
                path='/'
                element={
                    evaluateAction('ListServicePlans') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    evaluateAction('CreateServicePlan') ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/service-plans'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    evaluateAction('GetServicePlan') ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/service-plans'/>
                    )
                }
            />
        </Routes>
    );
};
