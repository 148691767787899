import {
    ReactElement,
    useEffect,
    useState,
} from 'react';

import {
    Box,
    Center,
    Checkbox,
    Grid,
    Group,
    Paper,
    Stack,
    Table,
    Text
} from '@mantine/core';

import {useMessages} from '../hooks/useMessages';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';

import PropTypes from 'prop-types';
import {useAccessControl} from '../../../hooks/useAccessControl';
import {useMessagesSelection} from '../hooks/useMessagesSelection';
import {
    IMessageTableColumn,
    Message,
} from '../types';
import {useMessagesSearch} from "../hooks/useMessagesSearch.ts";
import {useMessagesPaging} from "../hooks/useMessagesPaging.ts";
import {useMessagesTableColumns} from "../hooks/useMessagesTableColumns.ts";
import {SideMessagePanel} from "../components/SideMessagePanel.tsx";
import {AiOutlinePaperClip} from "react-icons/ai";
import {useAuth} from "../../../hooks/useAuth.ts";

const highlightText = (status: string, text: string) => {
    return status === 'READ' ? <Text fs={'sm'} fw={400} c={'gray'} style={{cursor: 'pointer'}}>{text}</Text> :
        <Text fs={'sm'} fw={700} style={{cursor: 'pointer'}}>{text}</Text>
}

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.username;

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)


    const isAdmin = [role].includes('Admin');

    const {
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
    } = useMessages();


    const {
        columns,
    } = useMessagesTableColumns();

    const {
        keyword,
        handleFilterByKeyword,
    } = useMessagesSearch();

    const {
        limit,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    } = useMessagesPaging();

    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useMessagesSelection();

    const [selectedRecord, setSelectedRecord] = useState<Message | null>(null);

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {


        let cols: IMessageTableColumn[] = [];
        if (evaluateAction('DeleteMessage')) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (isAdmin || item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}>n/a</Checkbox></Center>);
                              }

                          },
                      },
            );
        }

        cols.push({
                      id: 'from',
                      title: 'From',
                      render: item => {
                          return (
                              <Group>

                                  <Text
                                      fs={'sm'}
                                      onClick={() => {
                                          setSelectedRecord(item);
                                      }}
                                      style={{cursor: 'pointer'}}>{item.fromText}</Text>

                                  <Box> {item.attachments && item.attachments.length > 0 ?
                                      <AiOutlinePaperClip/> : null}</Box>

                              </Group>
                          )

                      },

                  },
        );

        cols.push({
                      id: 'subject',
                      title: 'Subject',
                      render: item => {
                          if (!item.subject) {
                              return <Text fs={'sm'} fw={700}>n/a</Text>
                          }

                          // shorten the subject if it is too long
                          if (item.subject.length > 40) {
                              return <Box onClick={() => {
                                  setSelectedRecord(item);
                              }}
                              >
                                  {highlightText(item.status as string, item.subject.substring(0, 40) + '...')}
                              </Box>
                          }
                          return <Box onClick={() => {
                              setSelectedRecord(item);
                          }}
                          >
                              {highlightText(item.status as string, item.subject as string)}</Box>

                      },
                  },
        );

        cols = cols.concat(columns);


        return cols;

    };


    return (
        <>
            <Paper p={'md'} shadow={'sm'}>
                <Grid>
                    <Grid.Col span={7}>
                        <Stack>
                            <SimpleTableBatchAction
                                limit={limit}
                                handleItemsPerPageChange={handleItemsPerPageChange}
                                settings={settings}
                                keyword={keyword}
                                handleFilterByKeyword={handleFilterByKeyword}
                                extraTools={extraTools}
                            />

                            <Table verticalSpacing='sm' striped highlightOnHover>
                                <TableHeaderRow
                                    columns={getColumns() as TableHeaderColumn[]}
                                    handleSelectAllRows={handleSelectAllRows}
                                />
                                <TableBody
                                    items={visibleItems}
                                    loading={loading}
                                    selection={selection}
                                    columns={getColumns() as TableBodyColumn[]}
                                />
                            </Table>

                            <SimpleTablePagination
                                totalPages={totalPages}
                                visibleCount={visibleCount}
                                handlePageChange={handlePageChange}
                            />
                        </Stack>

                    </Grid.Col>
                    <Grid.Col span={5}>
                        {selectedRecord ? <SideMessagePanel record={selectedRecord}/> :
                            <Paper p={'sm'} shadow={'sm'}><Text>Select Item to view details</Text></Paper>}
                    </Grid.Col>

                </Grid>
            </Paper>


        </>
    );
};

RecordsTable.propTypes = {
    extraTools: PropTypes.array,
    settings: PropTypes.object,
};