import {stage} from '../stage';

const appsync = {
    prod: {
        endpoint: 'https://wn24qdlht5fvflqg6f7jkhxqri.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    test: {
        endpoint: 'https://xwccyhsa4rewbjsdsrljef7w4i.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    dev: {
        endpoint: 'https://nkmlwznluze6helsvr2pkrhali.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    ide: {
        endpoint: 'https://nkmlwznluze6helsvr2pkrhali.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
};

export const appSyncConfig = appsync[stage];
