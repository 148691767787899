import {
    deleteContact,
    fetchContact,
    saveContact,
    updateContact,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Contact} from '../types';

export const useContact = (contactId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, contactId ? contactId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof contactId === 'undefined') {
            throw new Error('contactId is required parameter');
        }
        dispatch(fetchContact(contactId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Contact) => {
        dispatch(saveContact(values));
    };

    const updateRecord = (values: Contact) => {
        if (typeof contactId === 'undefined') {
            throw new Error('contactId is required parameter');
        }

        dispatch(updateContact({
                                   id: contactId,
                                   record: values,
                               }));
    };

    const deleteRecord = () => {
        if (typeof contactId === 'undefined') {
            throw new Error('contactId is required parameter');
        }
        dispatch(deleteContact(contactId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
