import {
    Box,
    Center,
    Grid,
    Group,
    Paper,
    Stack,
    Title
} from "@mantine/core";

import classes from "./MarketingComponent.module.css";
import {CallToActionComponent} from "./CallToActionComponent.tsx";
import {products} from "./product-descriptions.tsx";
import {MediumLogo} from "../../../components/logo/MediumLogo.tsx";
import {AiOutlineArrowRight} from "react-icons/ai";
import {FlexiButton} from "@flexinet/ui-components";
import {useNavigate} from "react-router-dom";

export const MarketingComponent = () => {

    const navigate = useNavigate()

    return (
        <Box className={classes.marketingComponent}>
            <Grid className={classes.heroImage}>
                <Grid.Col span={3}>
                    {products.map((product, index) => {
                        return (
                            <Paper shadow={'sm'} p={'lg'} my={"lg"} key={index}>
                                <Center>
                                    {product.smallIcon}
                                </Center>
                                <Center>
                                    <h2 className="section-title">{product.title}</h2>
                                </Center>
                                <p>{product.solution}</p>
                                <Group justify={'flex-end'}>
                                    <FlexiButton variant={'subtle'} onClick={() => navigate(product.path)}
                                                 rightSection={<AiOutlineArrowRight/>}>details</FlexiButton>
                                </Group>
                            </Paper>
                        )
                    })}

                </Grid.Col>

                <Grid.Col span={4}>
                    <Center className={classes.appTitle}>
                        <Stack>
                            <MediumLogo/>
                            <Title>Organize your important communications with central email delivery and
                                storage</Title>
                            <CallToActionComponent/>
                        </Stack>
                    </Center>
                </Grid.Col>

            </Grid>
        </Box>
    )

}
