export const createServicePlan = `
    mutation createServicePlan($input: CreateServicePlanInput!) {
       createServicePlan(input:$input) {
            id
            name
            description
            website
            createdByUsername
            created
            updated
       }
}`

export const updateServicePlan = `
    mutation updateServicePlan($input: UpdateServicePlanInput!) {
        updateServicePlan(input:$input){
            id
            name
            description
            website
            createdByUsername
            created
            updated
        }
}`

export const deleteServicePlan = `
    mutation deleteServicePlan($input: DeleteServicePlanInput!) {
        deleteServicePlan(input:$input) {
            id
        }
}`