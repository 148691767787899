import {PlatformNavbar} from "@flexinet/ui-components";
import {getMenuItems} from "../data/menus";
import {useAccessControl} from "../../../hooks/useAccessControl.ts";
import {useAuth} from "../../../hooks/useAuth.ts";

export const PortalNavbar = () => {

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const items = getMenuItems()


    const menuItems = items.filter((item) => {
        return evaluateAction(item.rbac as string)
    });


    return <PlatformNavbar navbarHeader={'Flexi Email'} menuItems={menuItems}/>

};