export const listMessages = `
   query listMessages {
       listMessages {
           items {
              id
              messageId
              fromText
              fromHtml
              toText
              toHtml
              subject
              date
              htmlPath
              htmlObjectKey
              attachments {
                  filename
                  filePath
                  objectKey
              }
              status
              created
              updated
           }
       }
   }
`;

export const getMessage = `
   query getMessage($id:ID!) {
       getMessage(id:$id) {
         id
         messageId
         fromText
         toText
         subject
         date,
         htmlPath
         textPath
         attachments {
          filename
          filePath
          objectKey
         }
         status
         created
         updated
       } 
}`;
