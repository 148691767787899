import {
    Card,
    Grid,
    Group,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    useMantineTheme
} from '@mantine/core';
import {AiOutlineCreditCard} from "react-icons/ai";
import {
    FlexiButton,
    FlexiCloseButtonIcon
} from "@flexinet/ui-components";
import {useNavigate} from "react-router-dom";
import {useToggle} from "@mantine/hooks";

export const SubscriptionStatusBanner = () => {

    const [hide, toggle] = useToggle();

    const theme = useMantineTheme()

    const navigate = useNavigate()

    let color = theme.colors.green[6]

    const daysLeft = 31

    if (daysLeft < 20) {
        color = theme.colors.blue[6]
    }
    if (daysLeft < 10) {
        color = theme.colors.yellow[6]
    }
    if (daysLeft < 5) {
        color = theme.colors.red[6]
    }

    if (daysLeft > 30) {
        return null;
    }

    return (
        <>
            {!hide ?
                <Card shadow='sm'>
                    <Card.Section>
                        <Grid>
                            <Grid.Col span={1}>
                                <Group justify={'center'} p={'sm'}>
                                    <AiOutlineCreditCard size={40} color={color}/>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <Text p={'sm'}>
                                    Thank you for signing up for Flexi Domains! You have {daysLeft} days left in your
                                    trial to
                                    experiment
                                    with
                                    features that
                                    are not in the Free plan. Like what you‘re seeing? Please enter your billing
                                    information
                                    here.
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <Group justify={"center"} p={'sm'}>
                                    <FlexiButton onClick={() => navigate('/settings/subscriptions')}>View
                                        Plans</FlexiButton>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <Group justify={'flex-end'}>
                                    <FlexiButton variant={'subtle'}
                                                 leftSection={<FlexiCloseButtonIcon/>}
                                                 onClick={() => toggle()}></FlexiButton>

                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Card.Section>
                </Card> : null}
        </>
    );
};


export const SubscriptionStatusBannerSkeleton = () => {
    return (
        <Card>
            <SimpleGrid cols={2} my='lg'>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
                <Stack gap='lg' m='lg'>
                    <Skeleton height={15} mt={6} width='60%' radius='xl'/>
                    <Skeleton height={15} mt={6} width='100%' radius='xl'/>
                </Stack>
            </SimpleGrid>
        </Card>
    );
};
