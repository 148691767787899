export const createAlias = `
    mutation createAlias($input: CreateAliasInput!) {
        createAlias(input:$input) {
            id
            name
            description
            sub
            status
            createdByUsername
            created
            updated       
        }
}`;

export const updateAlias = `
    mutation updateAlias($input: UpdateAliasInput!) {
        updateAlias(input:$input){
            id
            name
            description
            sub
            status
            createdByUsername
            created
            updated       
        }
}`;

export const deleteAlias = `
    mutation deleteAlias($input: DeleteAliasInput!) {
        deleteAlias(input:$input) 
}`;
