import {useNavigate} from 'react-router-dom';

import {Button} from '@mantine/core';

import {useAccessControl} from '../../../hooks/useAccessControl';
import {useToggle} from '@mantine/hooks';


import {
    AiOutlineCloseCircle,
    AiOutlineEdit,
    AiOutlineProfile
} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {useAuth} from "../../../hooks/useAuth.ts";

export const useEditPageTools = () => {

    const navigate = useNavigate();

    const {role} = useAuth()
    const {evaluateAction} = useAccessControl(role as string)

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];


    if (evaluateAction('UpdateMessage')) {

        if (mode === 'edit') {
            tools.push(<Button radius='xs' size='md' variant={'filled'} key={'profile'}
                               leftSection={<IconContext.Provider value={{size: '24px'}}>
                                   <AiOutlineProfile/></IconContext.Provider>}
                               onClick={() => toggle()}>Profile</Button>);
        } else {
            tools.push(<Button leftSection={<AiOutlineEdit/>} variant={'filled'} key={'edit'}
                               onClick={() => toggle()}>Edit</Button>);
        }

    }

    tools.push(<Button leftSection={<AiOutlineCloseCircle/>} variant={'outline'} key={'close'}
                       onClick={() => navigate(`/messages`)}>Close</Button>);


    return {
        mode,
        tools,
    };

};
