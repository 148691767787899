import {showNotification} from "@mantine/notifications";
import {
    AiOutlineExclamation,
    AiOutlineInfo,
    AiOutlineLogin
} from "react-icons/ai";
import config from "../config";


export const showErrorNotifications = (e: any) => {

    console.debug('handleAppSyncResponseException', e)

    if (typeof e.errors !== 'undefined' && e.errors.length > 0) {
        e.errors.forEach((error: { message: string; }) => {
            showErrorNotification(error.message);
        })
    } else {
        showErrorNotification(e.message);
    }

}


export const showSuccessNotification = (message = 'Record saved successfully') => {
    showNotification({
                         title: 'Success',
                         icon: <AiOutlineInfo/>,
                         message: message,
                         color: 'teal',
                     })
}

export const showErrorNotification = (message = 'Error performing the operation') => {
    showNotification({
                         title: 'Error',
                         icon: <AiOutlineExclamation/>,
                         message: message,
                         color: 'red',
                     })

    const noCurrentUserMessage = 'no current user'
    if (message.toLowerCase() === noCurrentUserMessage) {
        showSessionExpiredNotification();
    }


}

export const showSessionExpiredNotification = (message = 'Redirecting to network portal...Please, wait!') => {

    showNotification({
                         title: 'Session Expired',
                         icon: <AiOutlineLogin/>,
                         message: message,
                         color: 'blue',
                     });

    setTimeout(() => {
        window.location.assign(config.platform.portal + '/sign-in?returnTo=' +
                                   config.platform.email);
    }, 3000);

};

