import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {Message} from '../types';


export const columns = [
    {
        title: I18n.get('Received'),
        width: '1fr',
        render: (item: Message) => formatTimestamp(item.date as string),
    },
];
